import React, { useState } from 'react';
import Layout from '../../components/layout';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../../components/seo';

const Projects = ({ data }) => {
  const StyledProjects = styled.div`
    .top-bg {
      height: 30rem;
      position: relative;
      h1 {
        position: absolute;
        bottom: -5rem;
        left: 50%;
        margin-bottom: 0;
        transform: translate(-50%, -50%);
        color: #ffffff;
        background: ${props => props.theme.colors.org};
        padding: 1.5rem 2.5rem;
        font-size: 2.5rem;
      }
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .filters {
      margin-top: 10rem;
      text-align: center;
      a {
        margin: 0 0.5rem;
        text-decoration: none;
        font-size: 1.5rem;
        padding: 1.5rem 3rem;
        border: 1px solid ${props => props.theme.colors.org};
        color: #333333;
        &:hover {
          background: ${props => props.theme.colors.org};
          color: #ffffff;
        }
      }
      .current-project-page {
        background: ${props => props.theme.colors.org};
        color: #ffffff;
      }
    }
    .projects {
      margin-bottom: 5rem;
      margin-top: 2rem;
      .container {
        display: flex;
        flex-wrap: wrap;
        .project {
          width: 32%;
          margin: 3rem 0 0;
          margin-left: 1.5rem;
          background: #888888;
          position: relative;
          height: 45rem;
          transition: 0.5s all;
          &:hover {
            .project-info {
              left: 0;
              display: flex;
            }
            .photo img {
              transform: scale(1.3);
            }
          }
          .photo {
            height: 100%;
            overflow: hidden;
            img {
              transition: all ease 400ms;
            }
            .gatsby-image-wrapper {
              height: 100%;
            }
          }
          .project-info {
            position: absolute;
            bottom: 0;
            left: -3rem;
            z-index: 2;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: none;
            flex-direction: column;
            justify-content: flex-end;
            div {
              padding: 2rem;
            }
            a {
              color: ${props => props.theme.colors.org};
              text-decoration: none;
            }
            h2 {
              margin: 0;
              a {
                text-decoration: none;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .show-more {
      display: flex;
      justify-content: center;
      margin-bottom: 10rem;
      button {
        border: 2px solid ${props => props.theme.colors.org};
        background: transparent;
        padding: 1.5rem 3rem;
        border-radius: 3rem;
        font-size: 1.8rem;
        font-family: ${props => props.theme.fonts.sec};
        letter-spacing: 0.3rem;
        transition: 0.3s all;
        cursor: pointer;
        &:hover {
          background: ${props => props.theme.colors.org};
          color: #ffffff;
        }
      }
    }
    @media (max-width: 1024px) {
      .projects .container .project {
        width: 31.5%;
        height: 35rem;
      }
    }
    @media (max-width: 768px) {
      .top-bg {
        height: 20vh;
      }
      .filters .container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        a {
          flex: 0 0 auto;
        }
      }
      .projects .container .project {
        margin-left: 0;
        width: 100%;
        height: 25rem;
      }
    }
  `;

  const firstPage = data.allWordpressWpProjects.nodes.slice(0, 9);

  const [projects, setProjects] = useState(firstPage);
  const [allLoaded, setAllLoaded] = useState(false);

  const onClick = () => {
    let projectsRendered = projects;

    if (projects.length + 6 > data.allWordpressWpProjects.nodes.length) {
      projectsRendered = data.allWordpressWpProjects.nodes;
      setAllLoaded(true);
    } else {
      projectsRendered = data.allWordpressWpProjects.nodes.slice(0, projects.length + 6);
    }

    setProjects(projectsRendered);
  };

  const renderProject = (project, i) => {
    const slug = project.acf.project_category.toLowerCase().replace('/', '-');
    return (
      <div className="project">
        <Link to={`/project/${project.slug}`}>
          <div className="photo">
            <Img
              fluid={
                project.acf.project_image.localFile.childImageSharp
                  .fluid
              }
            />
          </div>
        </Link>
        <div className="project-info">
          <div>
            <h2>
              <Link to={`/project/${project.slug}`}>
                {project.acf.project_name}
              </Link>
            </h2>
            <p>
              <Link to={`/projects/${slug}`}>
                {project.acf.project_category}
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderMoreBtn = () => {
    if(!allLoaded && data.allWordpressWpProjects.nodes.length > 9) {
      return (
        <button onClick={onClick}>Show More</button>
      )
    }
  }

  return (
    <Layout>
      <SEO title="Showrooms - Industrial" />
      <StyledProjects>
        <div className="top-bg">
          <Img fluid={data.file.childImageSharp.fluid} />
          <h1>Showrooms / Industrial</h1>
        </div>
        <div className="filters">
          <div className="container">
            <Link activeClassName="current-project-page" to="/projects">
              All
            </Link>
            <Link
              activeClassName="current-project-page"
              to="/projects/hospitality"
            >
              Hospitality
            </Link>
            <Link
              activeClassName="current-project-page"
              to="/projects/residential-offices"
            >
              Residential/Offices
            </Link>
            <Link
              activeClassName="current-project-page"
              to="/projects/showrooms-industrial"
            >
              Showrooms/Industrial
            </Link>
            <Link
              activeClassName="current-project-page"
              to="/projects/warehouse-villas"
            >
              Warehouse/Villas
            </Link>
          </div>
        </div>
        <div className="projects">
          <div className="container">
            {projects.map((project, i) => renderProject(project, i))}
          </div>
        </div>
          <div className="show-more">
            {renderMoreBtn()}
          </div>
      </StyledProjects>
    </Layout>
  );
};

export const data = graphql`
  {
    file(relativePath: { eq: "projects.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpProjects(filter: {acf: {project_category: {eq: "Showrooms/Industrial"}}}) {
      nodes {
        acf {
          project_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          project_name
          project_category
        }
      }
    }
  }
`;

export default Projects;
